import React, {PropsWithChildren, useCallback, useMemo} from "react";
import {HttpError, useCreate, useList} from "@refinedev/core";
import {ICart, ICartProduct, IProduct, IPromoCode} from "types";
import {useGlobalSettings} from "./globalSettings";
import {ERRORS} from "types/constants";
import {makeSound} from "libs/utils";
import {useCatalogData} from './catalogData';
import {usePayment} from './payment';


const initialState: CartState = {
  cart: {
    initial_amount: 0,
    lines: [],
    total_amount: 0
  },
  products: [],

  promoCodes: [],
  selectedPromoCode: '',
  promoCodeError: null,
  selectedMarkedProduct: null,
  isLoading: false,
  isSuccess: false,
  isError: false,

  markingCodeError: null,
  isMarkingCodeError: false,
  displayProductMarketModal: false,

  error: null
};

interface CartState {
  cart: ICart;
  products: ICartProduct[],

  promoCodes: IPromoCode[],
  selectedPromoCode: string,
  promoCodeError?: ERRORS | null,
  selectedMarkedProduct: IProduct | null,
  isLoading?: boolean,
  isSuccess?: boolean,
  isError?: boolean,

  markingCodeError?: ERRORS | null,
  isMarkingCodeError?: boolean,
  displayProductMarketModal?: boolean,

  error?: any
}

interface ICartContext extends CartState {
  onAddItem: (product: IProduct, count?: number, markingCode?: string) => Promise<void>;
  onDeleteItem: (productId: string, count?: number) => Promise<void>;
  onResetCart: () => Promise<void>;
  onUpdateCart: (code?: string) => Promise<void>;
  openMarkedModal: () => void;
  closeMarkedModal: () => void;

  onChangePromoCode: (code: string) => void;
  onValidatePromoCode: (code: string) => boolean;
  onApplyPromoCode: (code: string) => void;
  onSetPromoCodeError: (error:  ERRORS | null) => void;
}

const CartContext = React.createContext<ICartContext | null>(null);

type Action =
  | {
  type: "CHANGE_CART";
  cart: ICart
} | {
  type: "ADD_PRODUCT";
  product: IProduct,
  isMarked: boolean,
  markingCode: string | null,
  quantity: number
} | {
  type: "DELETE_PRODUCT";
  productId: string,
  productIndex: number | undefined,
  quantity: number
} | {
  type: "SELECT_PROMOCODE";
  code: string
} | {
  type: "OPEN_MARKED_MODAL";
} | {
  type: "CLOSE_MARKED_MODAL";
} | {
  type: "SET_MARKED_ERROR";
} | {
  type: "SELECT_MARKED_PRODUCT";
  product: IProduct
} | {
  type: "RESET_CART_STATE";
} | {
  type: "SET_PROMO_CODE_ERROR";
  error: ERRORS | null
}

// TODO: add immutable
function cartReducer(state: CartState, action: Action): CartState {
  switch (action.type) {
    case "CHANGE_CART": {
      //debugger;
      return {
        ...state,
        promoCodeError: null,
        cart: action.cart,
      };
    }

    case "ADD_PRODUCT": {
      const {isMarked, product, markingCode, quantity} = action

      const isNewProduct = state?.products?.every((p: ICartProduct) => p.id !== product.id)

      return {
        ...state,
        products: !state?.products ? [] : isMarked || isNewProduct ? [...state?.products, {
          ...product,
          quantity: quantity || 1,
          markingCode: markingCode || ''
        }] : state?.products.map((p: ICartProduct) => ({
          ...p,
          quantity: p.id === product.id ? p.quantity + quantity : p.quantity
        })),
        markingCodeError: null,
        isMarkingCodeError: false,
        displayProductMarketModal: false
      };
    }

    case "DELETE_PRODUCT": {
      const {productId, quantity} = action
      const productIndex = state.products?.findIndex(p => p.id === productId)
      const cartProduct = state.cart?.lines.find(p => p?.product_id === productId)

      if (productIndex !== undefined && productIndex > -1) {
        return {
          ...state,
          products: state.products?.[productIndex]?.quantity - quantity <= 0 || !cartProduct ?
            state?.products?.reduce((arr: ICartProduct[], p, idx) => idx === productIndex ? arr : [...arr, p], []) || [] :
            state?.products?.map((p: ICartProduct) => ({
              ...p,
              quantity: p.id === productId ? p.quantity - quantity <= 0 ? 0 : p.quantity - quantity : p.quantity
            })),
        };
      }

      return state
    }

    case "RESET_CART_STATE": {
      return initialState;
    }

    case "SET_MARKED_ERROR": {
      return {
        ...state,
        markingCodeError: ERRORS.MARKED_PRODUCT_IS_ADDED,
        isMarkingCodeError: true
      };
    }


    case "SELECT_PROMOCODE": {
      return {
        ...state,
        selectedPromoCode: action.code || ''
      };
    }

    case "OPEN_MARKED_MODAL": {
      return {
        ...state,
        displayProductMarketModal: true
      };
    }

    case "CLOSE_MARKED_MODAL": {
      return {
        ...state,
        displayProductMarketModal: false,
        selectedMarkedProduct: null,
        markingCodeError: null
      };
    }

    case "SELECT_MARKED_PRODUCT": {
      return {
        ...state,
        selectedMarkedProduct: action.product || null
      };
    }

    case "SET_PROMO_CODE_ERROR": {
      return {
        ...state,
        promoCodeError: action.error || null
      };
    }

  }
}

export const CartProvider: React.FC<PropsWithChildren> = React.memo(({children}) => {
  const {mutateAsync: createMutateAsync, isLoading, isError, isSuccess, error} = useCreate<any>({});
  //const {mutate: deleteMutate} = useDelete<any>();

  const propsGlobal = useGlobalSettings();
  const settings = propsGlobal?.settings

  const [state, dispatch] = React.useReducer(cartReducer, initialState);

  const {data: promoCodes, refetch: onRefetchPromoCode} = useList<IPromoCode>({
    resource: "store/promo_codes/",
    dataProviderName: "promoCodes"
  })

  const setChangeCart = useCallback(
    (cart: ICart) => dispatch({type: "CHANGE_CART", cart}),
    [dispatch],
  );

  const addProduct = useCallback(
    ({
       product,
       isMarked,
       markingCode,
       quantity
     }: { product: IProduct, isMarked: boolean, markingCode: string | null, quantity: number }) => dispatch({
      type: "ADD_PRODUCT",
      product,
      markingCode,
      isMarked,
      quantity
    }),
    [dispatch],
  );

  const deleteProduct = useCallback(
    (productId: string, productIndex: number | undefined, quantity: number) => dispatch({
      type: "DELETE_PRODUCT",
      productId,
      productIndex,
      quantity
    }),
    [dispatch],
  );

  const setPromoCode = useCallback(
    (code: string) => dispatch({type: "SELECT_PROMOCODE", code}),
    [dispatch],
  );

  const openMarkedModal = useCallback(
    () => dispatch({type: "OPEN_MARKED_MODAL"}),
    [dispatch],
  );

  const closeMarkedModal = useCallback(
    () => dispatch({type: "CLOSE_MARKED_MODAL"}),
    [dispatch],
  );

  const selectMarkedProduct = useCallback(
    (product: IProduct) => dispatch({type: "SELECT_MARKED_PRODUCT", product}),
    [dispatch],
  );

  const resetCartState = useCallback(
    () => dispatch({type: "RESET_CART_STATE"}),
    [dispatch],
  );

  const setMarkedError = useCallback(
    () => dispatch({type: "SET_MARKED_ERROR"}),
    [dispatch],
  );

  const setPromoCodeError = useCallback(
    (error: ERRORS | null) => dispatch({type: "SET_PROMO_CODE_ERROR", error}),
    [dispatch],
  );


  const getPayloadBasket: () => any = () => {
    return {
      ...state.products.reduce((obj, line) => ({
        ...obj,
        [line.id]: state.products.reduce((count, p) => p.id === line.id ? count + p.quantity : count, 0) || 0
      }), {})
    }
  }

  const handleErrorRequest = (error: HttpError) => {
    if (error?.response?.data?.error?.code === 1100) {
      setPromoCodeError(ERRORS.PROMO_CODE_INVALID)
      return;
    }

    if (error?.response?.data?.error?.code === 1101) {
      setPromoCodeError(ERRORS.PROMO_CODE_NOT_UNIQUE)
      return;
    }
  }

  const handleItemAdd = useCallback(async (product: IProduct, count = 1, code?: string) => {
    if (settings?.productMarkingEnabled && product?.isMarked && !code) {
      openMarkedModal();
      selectMarkedProduct(product);
      return;
    }

    if (!!(settings?.productMarkingEnabled && product?.isMarked) && code) {
      if (state?.products?.some((p: ICartProduct) => p?.markingCode === code)) {
        setMarkedError();
        return;
      }
    }

    const payloadBasket = getPayloadBasket();
    const payload = {
      basket: {
        ...payloadBasket,
        [product.id]: !payloadBasket[product.id] ? count : payloadBasket[product.id] + count
      },
      item_removed_from_cart: false,
      promo_code: state.selectedPromoCode
    }

    addProduct({
      product: {
        ...product
      },
      isMarked: !!(settings?.productMarkingEnabled && product?.isMarked),
      markingCode: code || null,
      quantity: count
    })

    await createMutateAsync(
      {
        resource: 'basket/recalculate/',
        dataProviderName: 'custom',
        values: payload,
        errorNotification: false
      },
      {
        onSuccess: ({ data }) => {
          setChangeCart(data)
        },
        onError: (error) => {
          if (product && error?.response?.data?.error?.code !== 1100 && error?.response?.data?.error?.code !== 1101) {
            deleteProduct(product.id, 0, count)
          }
          handleErrorRequest(error)
        },
      },
    );
  }, [settings, state]);

  const handleItemDelete = async (productId: string, count = 1) => {
    const payloadBasket = getPayloadBasket();
    let fullRemoved = false;

    if (payloadBasket[productId] - count <= 0) {
      fullRemoved = true
      delete payloadBasket[productId]
    } else {
      payloadBasket[productId] = payloadBasket[productId] - count
    }

    const payload = {
      basket: payloadBasket,
      item_removed_from_cart: true,
      promo_code: Object.keys(payloadBasket)?.length === 0 ? null : state.selectedPromoCode
    }

    const savingProduct = state.products.find(p => p.id === productId)
    deleteProduct(productId, 0, (!payloadBasket[productId] || payloadBasket[productId]) <= 0 ? 0 : count)


    try {
      await createMutateAsync(
        {
          resource: 'basket/recalculate/',
          dataProviderName: 'custom',
          values: payload,
          errorNotification: false
        },
        {
          onSuccess: ({ data }) => {
            if (Object.keys(payloadBasket)?.length === 0) {
              setPromoCode('')
            }
            if (fullRemoved && settings?.sounds?.productRemovalFromCart?.enabled) {
              makeSound(settings?.sounds?.productRemovalFromCart?.fileUrl || '')
            }
            setChangeCart(data);
            return data
          },
          onError: (error) => {
            if (savingProduct && error?.response?.data?.error?.code !== 1100 && error?.response?.data?.error?.code !== 1101) {
              addProduct({
                product: savingProduct,
                isMarked: !!(settings?.productMarkingEnabled && savingProduct?.isMarked),
                markingCode: savingProduct?.markingCode || null,
                quantity: count
              })
            }
            handleErrorRequest(error)
          },
        },
      );

      return payloadBasket;
    } catch (e) {
      console.log(e)
    }
  };

  const handleCartUpdate = async (code = '') => {
    const payloadBasket = getPayloadBasket()

    const payload = {
      basket: payloadBasket,
      item_removed_from_cart: false,
      promo_code: code === undefined ? state.selectedPromoCode : code
    }
    try {
      await createMutateAsync(
        {
          resource: 'basket/recalculate/',
          dataProviderName: 'custom',
          values: payload,
          errorNotification: false
        },
        {
          onSuccess: ({ data }) => {
            setChangeCart(data)
          },
          onError: (error) => {
            handleErrorRequest(error)
          },
        },
      );
    } catch (e) {
      console.log(e)
    }
  };

  const handleCartReset = async () => {
    const payload = {
      basket: {},
      item_removed_from_cart: true,
      promo_code: null
    }
    onRefetchPromoCode()
    await createMutateAsync(
      {
        resource: 'basket/recalculate/',
        dataProviderName: 'custom',
        values: payload,
        successNotification: false
      },
      {
        onSuccess: ({ data }) => {
          setChangeCart(data)
          resetCartState()
        },
        onError: (error) => {
          handleErrorRequest(error)
        },
      },
    );
  };

  const handlePromoCodeValidate = (promoCode: string) => {
    return !!(promoCodes?.data?.length && promoCodes?.data.some((code: IPromoCode) => code.state && code?.code.toUpperCase() === promoCode.toUpperCase()))
  }

  const handlePromoCodeApply = async (promoCode: string) => {
    setPromoCode(promoCode)
    await handleCartUpdate(promoCode)
  }

  const value = useMemo(
    () => ({
      isLoading,
      isSuccess,

      isError,
      error,

      promoCodes: promoCodes?.data || [],

      selectedMarkedProduct: state.selectedMarkedProduct,
      displayProductMarketModal: state.displayProductMarketModal,
      isMarkingCodeError: state.isMarkingCodeError,
      markingCodeError: state.markingCodeError,

      cart: state.cart,
      products: state.products,
      selectedPromoCode: state.selectedPromoCode,
      promoCodeError: state.promoCodeError,
    }),
    [state, isLoading, isSuccess, isError, error, openMarkedModal, closeMarkedModal, promoCodes],
  );

  return (
    <CartContext.Provider
      value={{
        ...value,

        openMarkedModal,
        closeMarkedModal,


        onAddItem: handleItemAdd,
        onDeleteItem: handleItemDelete,
        onResetCart: handleCartReset,
        onUpdateCart: handleCartUpdate,

        onChangePromoCode: setPromoCode,
        onValidatePromoCode: handlePromoCodeValidate,
        onApplyPromoCode: handlePromoCodeApply,
        onSetPromoCodeError: setPromoCodeError
      }}
    >
      {children}
    </CartContext.Provider>
  );
});

export const useCartContext = (): ICartContext => {
  const context = React.useContext(CartContext);
  if (context === null) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
