import {IPaymentMethod, IPaymentStepsProps} from "types";
import React, {useEffect, useMemo, useState} from "react";
import {Col, Row} from "antd";
import {useTranslate} from "@refinedev/core";
import {CustomButton} from "components/ui";
import {useCartContext, useGlobalSettings, usePayment} from "context";
import {PaymentMethodsWrapper, PaymentMethodWrapper} from "components/Payment/Payment.style";
import {EBonusSystemType, EPaymentMethods, EPaymentSteps} from "types/constants";
import {BankCartIcon, BonusIcon, OnLunchIcon, QRCodeIcon, SBPIcon, YandexBadgeIcon, YandexFoodIcon} from "./icons";
import {BonusCardAuth} from "components/BonusCardAuth";
import {getMaxSliderBonusesValue, useGetMaxBonusesValue} from '../../../libs/hooks';


export const SelectPaymentMethod: React.FC<IPaymentStepsProps> = React.memo(({ goNextStep, goPreviousStep }) => {

  const t = useTranslate();
  const { cart } = useCartContext();

  const { settings } = useGlobalSettings()
  const { bonusCard, selectedPaymentMethod, isErrorBonusCard, isCheckingBonusCardNumber, onClearBonusCard, onSelectPaymentMethod,
    onChangeSpentBonusCount } = usePayment()

  const [displayKeyboard, setDisplayKeyboard] = useState<boolean>(false)

  const bonusTypes = useMemo(() => Object.values(EBonusSystemType), [EBonusSystemType])
  const isSelectedMethodBonus = useMemo(() => Object.values(EBonusSystemType).includes(selectedPaymentMethod as unknown as EBonusSystemType), [selectedPaymentMethod, EBonusSystemType])

  const { maxSliderValue, canFullPayment, payWithMaxBonuses } = useGetMaxBonusesValue()

  useEffect(() => {
    setDisplayKeyboard(isSelectedMethodBonus)
  }, [isSelectedMethodBonus])

  const paymentMethods: IPaymentMethod[] = useMemo(() => (
    [
      {
        title: t("payment.methods.card"),
        key: EPaymentMethods.PAYMENT_TYPE_CARD,
        visible: !!settings?.posTerminal?.paymentEnabled,
        icon: <BankCartIcon isSelected={selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_CARD}/>
      },
      {
        title: t("payment.methods.sbp"),
        key: EPaymentMethods.PAYMENT_TYPE_SBP,
        visible: !!settings?.sbpQrEnabled,
        icon: <SBPIcon/>
      },
      {
        title: t("payment.methods.yandexFood"),
        key: EPaymentMethods.PAYMENT_TYPE_YANDEX_EDA,
        visible: !!settings?.yEdaEnabled,
        icon: <YandexFoodIcon/>
      },
      {
        title: t("payment.methods.yandexQR"),
        key: EPaymentMethods.PAYMENT_TYPE_YANDEX_QR,
        visible: !!settings?.yQrEnabled,
        icon: <QRCodeIcon isSelected={selectedPaymentMethod === EPaymentMethods.PAYMENT_TYPE_YANDEX_QR}/>
      },
      {
        title: t("payment.methods.yandexBadge"),
        key: EPaymentMethods.PAYMENT_TYPE_YANDEX_BADGE,
        visible: !!settings?.ybadgeEnabled,
        icon: <YandexBadgeIcon/>
      },
      {
        title: t("payment.methods.launch"),
        key: EPaymentMethods.PAYMENT_TYPE_ON_LUNCH,
        visible: !!settings?.nalunchEnabled,
        icon: <OnLunchIcon/>
      },
      {
        title: t(`payment.methods.${settings?.bonusSystemType}`),
        key: settings?.bonusSystemType || EBonusSystemType.GOPOEDIM,
        visible: !!settings?.posTerminal?.paymentEnabled && !!settings?.bonusSystemType,
        icon: <BonusIcon bonusSystemType={settings?.bonusSystemType} selectedPaymentMethod={selectedPaymentMethod as unknown as EBonusSystemType}/>
      }
    ].filter(m => m.visible)
  ), [settings, selectedPaymentMethod, t])

  const handlePaymentMethodSelect = (method: EPaymentMethods | EBonusSystemType | null) => {
    if (bonusTypes.includes(method as unknown as EBonusSystemType)) {
      onSelectPaymentMethod(settings?.bonusSystemType || null)
    } else {
      onSelectPaymentMethod(method)
    }

    if (payWithMaxBonuses && bonusCard?.userId && canFullPayment) {
      goNextStep()
      return;
    }

    if (Object.values(EBonusSystemType).includes(method as unknown as EBonusSystemType)) {
      if (displayKeyboard || bonusCard?.cardId) {
        goNextStep(EPaymentSteps.WRITE_OFF_BONUSES)
      } else {
        //setDisplayKeyboard(true)
      }
    } else {
      goNextStep()
    }
  }

  const handleGoToPaymentWithBonuses = (step: EPaymentSteps, allBonus = 0) => {
    const maxValue = getMaxSliderBonusesValue(cart, settings, allBonus);
    if (payWithMaxBonuses) {
      onChangeSpentBonusCount(maxValue)
    }
    goNextStep(payWithMaxBonuses && (cart?.total_amount - maxValue === 0) ? EPaymentSteps.FINISH_PAYMENT : step)
  }

  return (
    <>
      <div className={"modal-header"}>{(
        (isSelectedMethodBonus && displayKeyboard) || settings?.payWithBonusPointsOnly) ?
        t(`payment.methods.${settings?.bonusSystemType}`) :
        t("payment.methods.header")}</div>

      {(settings?.payWithBonusPointsOnly || displayKeyboard) ? (
        <>
          {!isErrorBonusCard || isCheckingBonusCardNumber ? (
            <div className={"modal-description"}>{t(`payment.methods.description.${settings?.bonusSystemType}`)}</div>
          ) : (
            <div className={"modal-description error-message"}>{t("payment.methods.errors.bonusCardNotFound")}</div>
          )}
          <BonusCardAuth displayKeyboard={displayKeyboard}
                         onChangePaymentStep={handleGoToPaymentWithBonuses}
                         onChangeDisplayKeyboard={(v) => setDisplayKeyboard(v)} />
        </>
      ) : (
        <PaymentMethodsWrapper methodCount={paymentMethods?.length}>
          <Row className={`payment-methods-container`} gutter={[8, 8]}>
            {!settings?.payWithBonusPointsOnly && (
              <>
                {paymentMethods.map(m => (
                  <Col xs={12} sm={12} md={8} lg={8} key={m.key} className={"payment-methods-col"}>
                    <PaymentMethodWrapper
                      className={m.key !== selectedPaymentMethod ? '' : 'selected-method'}
                      onClick={() => handlePaymentMethodSelect(selectedPaymentMethod === m.key ? null : m.key)}>
                      {m.icon ? m.icon : <div className={"mock-image"}/>}
                      <span>{m.title}</span>
                    </PaymentMethodWrapper>
                  </Col>
                ))}

                {/*{(!!settings?.posTerminal?.paymentEnabled || (settings?.bonusSystemType === EBonusSystemType.OZON || settings?.bonusSystemType === EBonusSystemType.OBEDRU)) && (*/}
                {/*  <Col xs={12} sm={12} md={8} lg={8} key={'bonus'}>*/}
                {/*    <PaymentMethodWrapper*/}
                {/*      className={!isSelectedMethodBonus ? '' : 'selected-method'}*/}
                {/*      onClick={_showBonusView}>*/}
                {/*        <BonusIcon bonusSystemType={settings?.bonusSystemType} selectedPaymentMethod={selectedPaymentMethod as unknown as EBonusSystemType}/>*/}
                {/*        <span>{t(`payment.methods.${settings?.bonusSystemType}`)}</span>*/}
                {/*    </PaymentMethodWrapper>*/}
                {/*  </Col>*/}
                {/*)}*/}
              </>
            )}
          </Row>
        </PaymentMethodsWrapper>
      )}

      <div className={"payment-buttons"}>

        {settings?.payWithBonusPointsOnly ? (
          <CustomButton className={"gray-button"} onClick={() => goPreviousStep()}>{t("payment.buttons.back")}</CustomButton>
        ) : (
          <>
            {displayKeyboard ? (
              <CustomButton disabled={isCheckingBonusCardNumber} className={"gray-button"} onClick={() => {
                onClearBonusCard()
                onSelectPaymentMethod(null)
                setDisplayKeyboard(false)
              }}>{t("payment.buttons.back")}</CustomButton>
            ) : (
              <CustomButton className={"gray-button"} onClick={() => goPreviousStep()}>{t("payment.buttons.back")}</CustomButton>
            )}
          </>
        )}

        {/*{((isSelectedMethodBonus && displayKeyboard) || settings?.payWithBonusPointsOnly) && (*/}
        {/*  <CustomButton className={"red-button"} onClick={() => goNextStep(EPaymentSteps?.WRITE_OFF_BONUSES)}*/}
        {/*                disabled={!bonusCard?.cardId || isErrorBonusCard}>*/}
        {/*    {t("payment.buttons.next")}</CustomButton>*/}
        {/*)}*/}

        {/*{isSelectedMethodBonus ? (*/}
        {/*  <>*/}
        {/*    {displayKeyboard ? (*/}
        {/*      <CustomButton className={"red-button"} onClick={() => goNextStep()}*/}
        {/*                    disabled={!bonusCard?.cardId || isErrorBonusCard}>*/}
        {/*        {t("payment.buttons.next")}</CustomButton>*/}
        {/*    ) : (*/}
        {/*      <CustomButton className={"red-button"} onClick={() => bonusCard?.cardId ? goNextStep() : setDisplayKeyboard(true)}>*/}
        {/*        {t("payment.buttons.next")}</CustomButton>*/}
        {/*    )}*/}
        {/*  </>*/}
        {/*) : (*/}
        {/*  <CustomButton className={"red-button"} onClick={() => goNextStep()}*/}
        {/*                disabled={!settings?.payWithBonusPointsOnly ? !selectedPaymentMethod :*/}
        {/*                  !(selectedPaymentMethod || (!!bonusCard?.cardId && !isErrorBonusCard))}>*/}
        {/*    {t("payment.buttons.next")}</CustomButton>*/}
        {/*)}*/}
      </div>
    </>
  )
})
