import {NotificationProvider, Refine} from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import routerBindings, {
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {CatalogDataServer, CustomServer, PromoCodesServer} from "customProviders";
import {MainPage} from "pages/main";
import DiagnosticsPage from "pages/maintenance";
import "@refinedev/antd/dist/reset.css";
import 'styles/fonts/fonts.css';
import {API_URL} from "./types/constants";
import { ConfigProvider, notification } from "antd";


const notificationProvider: NotificationProvider = {
  open: ({ message, key, type, description, ...props }) => {

    if (type === "success") {
      notification?.success({
        message: description,
        //description: description,
        key: key,
        duration: 2
      })
    }

    if (type === "error") {
      notification?.error({
        message: message,
        description: description,
        key: key,
        duration: 2
      })
    }
  },
  close: (key) => {
    // console.log(key)
  }
};


function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language
  };

  return (
    <BrowserRouter>
      <ConfigProvider>
        <RefineKbarProvider>
          <Refine
            dataProvider={{
              default: dataProvider(API_URL),
              custom: CustomServer(API_URL),
              catalog: CatalogDataServer(API_URL),
              promoCodes: PromoCodesServer(API_URL),
            }}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            //authProvider={authProvider}
            i18nProvider={i18nProvider}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route index element={<MainPage />} />
              <Route path={'/maintenance'} element={<DiagnosticsPage />} />
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />

          </Refine>
        </RefineKbarProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
