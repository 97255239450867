import React, { useMemo } from "react";
import {useTranslate} from "@refinedev/core";
import {InView} from "react-intersection-observer";
import {IProduct} from "types";
import {ISettings, useCartContext, useUI} from "context";
import {ProductWrapper} from "./ProductPreview.style";
import {ProductImage} from "components/ProductImage";
import {unitsByCode} from "libs/utils";
import { useChangeProductCount } from "context/hooks";
import {MoneyUnits} from "components/common/MoneyUnits";
import {LocaleName} from '../common/LocaleName';


export const ProductPreview: React.FC<{
  product: IProduct,
  isSmall?: boolean,
  settings: ISettings | null,
  displayProduct?: boolean }> = React.memo(({ product, isSmall= false, displayProduct = true, settings }) => {
  const t = useTranslate()

  const { setSelectedProduct, openProductModal } = useUI()

  const { cart, products: cartProducts } = useCartContext()

  const {
    productQuantity: addingProductQuantity,
    onAddItemWithDebounce,
    onDeleteItemWithDebounce,
  } = useChangeProductCount(product, settings)

  const productQuantity = useMemo(() => {
    const q = cartProducts.reduce((count, p) => p.id === product?.id ? count + p.quantity : count, 0) || 0
    return q + addingProductQuantity <= 0 ? 0 : q + addingProductQuantity
  }, [addingProductQuantity, cartProducts, product])

  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => (
        <ProductWrapper key={product?.id} ref={ref} isSmall={isSmall}
                        style={{display: displayProduct ? 'block' : 'none'}}>
          {inView && (
            <>
              <div className={"product-info-container"} onClick={onAddItemWithDebounce}>
                <ProductImage className={!isSmall ? "" : "small-catalog-preview"} product={product}
                              onOpenInfo={(e: any) => {
                                e?.stopPropagation()
                                openProductModal()
                                setSelectedProduct(product)
                              }}
                              isPreview={true} quantity={productQuantity}/>
                <div className={"product-name"}>
                  <LocaleName entity={product} langs={settings?.languages || []}/>
                </div>
                {!isSmall && (
                  <div className={"product-info"}>
                    {!!product?.weight && (
                      <span>{product?.weight}&nbsp;{t(`product.${unitsByCode(product?.unit)}`)}</span>)}
                    {!!product?.calories && (
                      <span>{product?.calories}&nbsp;{t("product.kcal")}</span>)}
                  </div>
                )}
              </div>
              {cartProducts?.every(p => p.id !== product.id) ? (
                <div className={"product-price"} onClick={onAddItemWithDebounce}>{product.price}&nbsp;<MoneyUnits/></div>
              ) : (
                <div className={"product-counter"}>
                  <span onClick={onDeleteItemWithDebounce}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.8055 21.25H26.1857C26.8867 21.25 27.5 20.6807 27.5 19.9959C27.5 19.3193 26.8867 18.75 26.1857 18.75H13.8055C13.1396 18.75 12.5 19.3193 12.5 19.9959C12.5 20.6807 13.1396 21.25 13.8055 21.25Z" fill="#39BF71"/>
                    </svg>
                  </span>
                  <span className={"count"}>{productQuantity}</span>
                  <span onClick={onAddItemWithDebounce}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5 19.9956C12.5 20.7295 13.0944 21.3235 13.8287 21.3235H18.6801V26.1721C18.6801 26.8972 19.2657 27.5 20 27.5C20.7343 27.5 21.3287 26.8972 21.3287 26.1721V21.3235H26.1801C26.9056 21.3235 27.5 20.7295 27.5 19.9956C27.5 19.2705 26.9056 18.6765 26.1801 18.6765H21.3287V13.8279C21.3287 13.1028 20.7343 12.5 20 12.5C19.2657 12.5 18.6801 13.1028 18.6801 13.8279V18.6765H13.8287C13.0944 18.6765 12.5 19.2705 12.5 19.9956Z" fill="#39BF71"/>
                    </svg>
                  </span>
                </div>
              )}
            </>
          )}
        </ProductWrapper>
      )}
    </InView>
  )
})