import React, {useEffect} from "react";
import {
  useCartContext,
  useCatalogData,
  useGlobalSettings,
  usePayment,
  useSearchResults,
  useUI
} from "context";
import {API_URL} from "types/constants";
import {Layout} from "components/common";
import InputForScanning from 'components/common/InputForScanning/InputForScanning';
import {Col, Row} from "antd";
import {Cart} from "containers/Cart";
import {Catalog} from "containers/Catalog";
import {ProductModal} from "components/ProductModal";
import {PromoCodeModal} from "components/PromoCodeModal";
import {MarkedProductModal} from "components/MarkedProductModal";
import {SearchResults} from "containers/Search";
import {SuccessfulPaymentModal} from "components/SuccessfullPaymentModal";
import styled from "styled-components";
import {useGetLocale, useSetLocale} from "@refinedev/core";
import {productFormatter} from "customProviders";
import urlJoin from "url-join";


let inactiveTimeout: ReturnType<typeof setInterval> | null;

export const Main: React.FC = React.memo(() => {

  const locale = useGetLocale();
  const currentLocale = locale();
  const changeLanguage = useSetLocale();
  const { settings } = useGlobalSettings();

  const { displaySearchResults, isInactivityUser, toggleInactivityUser, displayBonusAuthModal,
    displayPromoModal, toggleDisplaySearchResults } = useUI();
  const { categories } = useCatalogData();
  const { displayPayment, isSuccessPayment } = usePayment();
  const { onChangeSearchValue, onSetSearchResult } = useSearchResults();
  const { cart, onAddItem, displayProductMarketModal, onValidatePromoCode, onApplyPromoCode } = useCartContext()

  useEffect(() => {
    changeLanguage(settings?.defaultLanguage || 'en')
  }, [settings])

  useEffect(() => {
    document.documentElement.setAttribute("lang", currentLocale || settings?.defaultLanguage || 'en');
    document.documentElement.setAttribute("dir", currentLocale === 'ar' ? 'rtl' : 'ltr');
  }, [currentLocale, settings])

  const handleAddCartItemByScan = async (value: string, valueWithoutSpace?: string) => {

    if (isInactivityUser) {
      toggleInactivityUser()
    }

    const scannedCode = valueWithoutSpace || value

    if (onValidatePromoCode(scannedCode) && cart?.lines?.length) {
      await onApplyPromoCode(scannedCode);
      return;
    }

    //const codeIsBarcode = new RegExp(/^[0-9]{8,20}$/).test(scannedCode)

    const params = new URLSearchParams({
      barcode: scannedCode
    })
    await fetch(`${urlJoin(API_URL, `/store/products/`)}?${params}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        let scanningProducts = (await response?.json()).map((p: any, i: number) => productFormatter(p, i))

        if (scanningProducts.length === 0) {
          onChangeSearchValue(value, categories)
          toggleDisplaySearchResults(true)
        } else {
          if (scanningProducts.length === 1) {
            await onAddItem(scanningProducts[0])
            toggleDisplaySearchResults(false)
          } else {
            onSetSearchResult(value, scanningProducts)
            toggleDisplaySearchResults(true)
            // onChangeSearchValue(value, categories)
            // toggleDisplaySearchResults(true)
          }
        }
      })
  }

  return (
    <Layout isInactivityUser={!!isInactivityUser}>
      {!displaySearchResults && (
        <Row className={"full-width flex-1"} gutter={[0, 10]} style={{height: 'calc(100vh - 133px)'}}>
          <CartCol span={12} lg={12} md={12} sm={24} xs={24} style={{height: 'calc(100vh - 133px)'}}>
            <Cart/>
          </CartCol>

          <CatalogCol span={12} lg={12} md={12} sm={24} xs={24} style={{height: 'calc(100vh - 133px)'}}>
            <Catalog/>
          </CatalogCol>
        </Row>
      )}

      <ProductModal/>

      <PromoCodeModal/>

      <MarkedProductModal/>

      {displaySearchResults && (
        <SearchResults/>
      )}

      <InputForScanning elementId={"scan-product"}
                        active={!displayPromoModal && !displayPayment &&
                          !displayProductMarketModal && !isSuccessPayment && !displayBonusAuthModal}
                        // active={!displaySearchResults && !displayPromoModal && !displayPayment &&
                        //   !displayProductMarketModal && !isSuccessPayment && !displayBonusAuthModal}
                        onSubmit={handleAddCartItemByScan}/>

      <SuccessfulPaymentModal/>
    </Layout>
  )
})


const CartCol = styled(Col)`
  padding-inline-end: 10px
`

const CatalogCol = styled(Col)`
  padding-inline-start: 10px; 
  overflow: hidden
`
