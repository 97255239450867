import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import intervalPlural from 'i18next-intervalplural-postprocessor';
import {DEFAULT_LANG, FALLBACK_LANGS, SUPPORTED_LANGS} from "types/constants";

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    supportedLngs: SUPPORTED_LANGS,
    lng: DEFAULT_LANG,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    ns: ["common", "maintenance"],
    defaultNS: "common",
    fallbackLng: FALLBACK_LANGS,
  });

export default i18n;
