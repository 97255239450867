import { DataProvider } from "@refinedev/core";
import axios, {AxiosInstance} from "axios";
import {ICategory, IProduct} from "types";
//import Cookies from "js-cookie";
import urlJoin from 'url-join';


export const axiosInstance = axios.create({
  headers: {}
});

const getImage = (product: any) => {
  if (product['UrlКартинки']) {
    return {
      width: 270,
      height: 405,
      src: product['UrlКартинки'],
    };
  }
  return false;
};

export const productFormatter = (product: any, index: number) => ({
  id: product.id,
  order: index,
  name: product.name,
  names: [product.name, product.name_l2],
  group: product['ТоварнаяГруппа_Ref_Key'],
  tagIds: product['tag_ids'],
  price:
    Number(product['ЦенаСоСкидкой']) &&
    Number(product['ЦенаСоСкидкой']) < product['Цена']
      ? product['ЦенаСоСкидкой']
      : product['Цена'],
  oldPrice:
    Number(product['ЦенаСоСкидкой']) &&
    Number(product['ЦенаСоСкидкой']) < product['Цена']
      ? product['Цена']
      : null,
  weight: product['Вес'],
  unit: product['ЕдиницаИзмерения'] || 'gram', // gram | milliliters
  vatType: product['ТипНДС'],
  isNew: product['Новинка'],
  isMarked: product['Маркируемый'],
  isOpen: false,
  isFavorite: false,
  calories: product['ККал'],
  carbohydrates: product['Углеводы'],
  fats: product['Жиры'],
  proteins: product['Белки'],
  composition: product['Ингредиенты'].filter((i: any) => i),
  image: getImage(product),
  codes: product['Штрихкоды'],
  balance:
    product['КоличествоОстаток'] && product['КоличествоОстаток'] > 0
      ? 10000
      : 0, // TODO: делаем невозможным купить весь товар hot fix до того как на сервере не будет актуальных данных
  onlyScan: !(
    product['КоличествоОстаток'] && product['КоличествоОстаток'] > 0
  ), // TODO: товары с нулевым колличеством доступны только через сканирование
})

const categoriesFormatter = (category: any, index: number) => ({
  id: category.id,
  order: index,
  name: category.name,
  names: [category.name, category.name_l2],
  productIds: category['Товары'],
  image: getImage(category),
  products: []
})

const tagsFormatter = (tag: any, index: number) => ({
  id: tag.id,
  order: index,
  name: tag.name,
})

const promotionFormatter = (promo: any, index: number) => ({
  id: promo.id,
  order: index,
  name: promo.name,
  names: [promo.name, promo.name_l2],
  description: promo.description,
  descriptions: [promo.description, promo.description_l2],
  imageUrl: promo.image_url,
  productIds: promo.products.map((product: any) => product.id),
})

export const CustomServer = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance,
): Omit<DataProvider, | "getMany" | "getOne" | "createMany" | "update" | "updateMany" | "deleteOne" | "deleteMany" | "getApiUrl" | "custom">  => ({
  getList: async ({
                    resource
                  }) => {
    const url = urlJoin(apiUrl, resource)

    const { data } = await httpClient.get(
      `${url}`,
    );

    const total = data.count;

    return {
      data,
      total,
    };
  },

  create: async ({
                   resource, variables
  }) => {
    const url = urlJoin(apiUrl, resource)

    const { data } = await httpClient.post(
      `${url}`,
      variables
    );

    return {
      data
    };
  }
})

export const PromoCodesServer = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance,
): Omit<DataProvider, | "getMany" | "getOne" | "create" | "createMany" | "update" | "updateMany" | "deleteOne" | "deleteMany" | "getApiUrl" | "custom">  => ({
  getList: async ({
                    resource
                  }) => {
    const url = urlJoin(apiUrl, resource)

    const { data } = await httpClient.get(
      `${url}`,
    );

    const total = data.count;

    return {
      data: data?.map((code: any, i: number) => ({
        code: code?.code,
        discount_id: code?.discount_id,
        id: code?.id,
        state: code?.state === "Активна"
      })),
      total,
    };
  }
})


export const CatalogDataServer = (  apiUrl: string,
                                  httpClient: AxiosInstance = axiosInstance):
  Omit<DataProvider, | "getMany" | "getOne" | "create" | "createMany" | "update" | "updateMany" | "deleteOne" | "deleteMany" | "getApiUrl" | "custom">  => ({
  // @ts-ignore
  getList: async (props) => {
    const config = {
      headers: {
        'Accept-Language': props.meta?.lang
      }
    }

    const response = await Promise.all([
      httpClient.get(
        urlJoin(apiUrl, '/store/products/'), {
          ...config,
          params: {
            only_actual: true,
            page_size: 1000000
          }
        }
      ),
      httpClient.get(
        urlJoin(apiUrl, '/store/categories/'), config),
      httpClient.get(
        urlJoin(apiUrl, '/store/tags/'), config),
      httpClient.get(
        urlJoin(apiUrl, '/store/promotions/'), config),
      httpClient.get(
        urlJoin(apiUrl, '/store/blocks/popular-products/'), config),
      httpClient.get(
        urlJoin(apiUrl, '/store/blocks/add-extra-products/'), config)
    ]);

    const productResponse = response[0]

    const categoryResponse = response[1]

    const tagResponse = response[2]

    const promotions = response[3]

    const popularProductIds = response[4]

    const extraForOrderProductsIds = response[5]

    let popularProducts = []
    let extraForOrderProducts = []

    let products = productResponse?.data?.map((p: any, i: number) => productFormatter(p, i))
    let categories = categoryResponse?.data?.map((c: any, i: number) => categoriesFormatter(c, i))
      //.filter((c: ICategory) => c.productIds && c.productIds.length)
      .sort((a: ICategory, b: ICategory) => a.order - b.order)

    try {
      let productCategories = categories?.reduce((arr: ICategory[], group: ICategory) => {
        return [...arr, {
          ...group,
          products: group.productIds.reduce((arr: IProduct[], pId) => {
            const product = products?.find((p: IProduct) => p.id === pId && !p.onlyScan);
            return !product ? arr : [...arr, product]
          }, [])
        }]
      }, [])

      if (products?.length && popularProductIds?.data?.length) {
        popularProducts = products?.filter((p: IProduct) => !!p && popularProductIds?.data?.find((pId: string) => pId === p.id))
      }

      if (products?.length && extraForOrderProductsIds?.data?.length) {
        extraForOrderProducts = products?.filter((p: IProduct) => !!p && extraForOrderProductsIds?.data?.find((pId: string) => pId === p.id))
      }

      const total = 0 //data.count;

      return Promise.resolve({
        data: {
          products: products,
          categories: productCategories,
          tags: tagResponse?.data?.map((t: any, i: number) => tagsFormatter(t, i)),
          promotions: promotions?.data?.map((p: any, i: number) => promotionFormatter(p, i)),
          popularProducts,
          extraForOrderProducts
        },
        total,
      });
    } catch (e) {
      console.log(e)
      return Promise.resolve({
        data: {
        },
        total: 0,
      });
    }

  }
})
