import React, {useEffect} from "react";
import {
  CartProvider, ManagedCatalogDataContext,
  ManagedGlobalSettingsContext, ManagedUIContext, PaymentProvider, SearchResultsProvider
} from "context";
import {API_URL, HEALTH_CHECK_DATA_TIME_SEC} from "types/constants";
import urlJoin from "url-join";
import {defaultTheme, GlobalStyle} from "themes";
import {useGetLocale} from "@refinedev/core";
import {ThemeProvider} from "styled-components";
import { Main } from "containers/Main";


export const MainPage: React.FC = () => {

  const locale = useGetLocale();
  const currentLocale = locale();

  useEffect(() => {
    setInterval(() => {
      healthCheck()
    }, HEALTH_CHECK_DATA_TIME_SEC * 1000)
  }, [])

  const healthCheck = () => {
    return fetch(urlJoin(API_URL, `/store/health_check/`), {
      method: 'POST',
    });
  };

  return (
    <ThemeProvider theme={{...defaultTheme, rtl: currentLocale === 'ar'}}>
      <ManagedUIContext>
        <GlobalStyle rtl={currentLocale === 'ar'}/>
        <ManagedGlobalSettingsContext>
          <ManagedCatalogDataContext>
            <CartProvider>
              <PaymentProvider>
                <SearchResultsProvider>
                  <Main/>
                </SearchResultsProvider>
              </PaymentProvider>
            </CartProvider>
          </ManagedCatalogDataContext>
        </ManagedGlobalSettingsContext>
      </ManagedUIContext>
    </ThemeProvider>
  )
}


